<template>
    <div class="h-100 w-100">
        <MapViewer class="p-0" @map-ready="onMapReady" />
    </div>
</template>

<script>
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/leaflet.markercluster.js";

import MapViewer from "./MapViewer";
import { LExtended as L } from "geoportal-extensions-leaflet";

const shapeStyle = {
    color: "#41A934",
    fillColor: "#AFCB22",
    fillOpacity: 0.4,
};

const PinIcon = L.DivIcon.extend({
    options: {
        className: "k-map-pin-base",
        iconAnchor: [20, 40],
        iconSize: [40, 40],
        popupAnchor: [0, -40],
        html: "<div/>",
    },
});
const SelectedPinIcon = PinIcon.extend({
    options: {
        className: "k-map-pin-selected",
    },
});

const defaultBasePin = new PinIcon();
const defaultSelectedPin = new SelectedPinIcon();

const SelectableMarker = L.Marker.extend({
    _baseIcon: defaultBasePin,
    _selectedIcon: defaultSelectedPin,

    options: {
        color: null,
        payload: null,
    },

    initialize: function(latlng, options) {
        L.Marker.prototype.initialize.call(this, latlng);
        L.setOptions(this, options);

        if (options.color) {
            const html = `<div style='background:${options.color};' />`;
            this._baseIcon = new PinIcon({ html });
            this._selectedIcon = new SelectedPinIcon({ html });
        }
        this.setIcon(this._baseIcon);
    },

    select: function() {
        this.setIcon(this._selectedIcon);
    },

    deselect: function() {
        this.setIcon(this._baseIcon);
    },
});

export default {
    components: {
        MapViewer,
    },

    props: {
        pois: { type: Array, required: true, default: () => [] },
        selected: { type: Number, required: false },
        clusterRadius: { type: Number, default: 15 },
    },

    data: function() {
        return {
            map: null,
            markers: {},
            group: null,
        };
    },

    watch: {
        selected(newValue, oldValue) {
            if (oldValue != null) {
                this.markers[oldValue].deselect();
            }
            if (newValue != null) {
                this.markers[newValue].select();
                this.centerMapOnPOI(newValue);
            } else {
                this.centerMapOnGroup();
            }
        },

        pois() {
            if (this.map) {
                this.initContent();
            }
        },
    },

    methods: {
        onMapReady(map) {
            this.map = map;
            map.pm.setLang("fr");
            map.pm.setPathOptions(shapeStyle);

            this.initContent();
        },

        initContent() {
            if (!this.group) {
                this.group = L.markerClusterGroup({
                    maxClusterRadius: this.clusterRadius,
                    animateAddingMarkers: true,
                }).addTo(this.map);
            } else {
                this.group.clearLayers();
            }
            this.pois.forEach(this.createMarker);
            this.group.refreshClusters();
            this.centerMapOnGroup();
        },

        createMarker(poi) {
            let marker = new SelectableMarker([poi.lat, poi.lng], {
                payload: poi.id,
                color: poi.color,
            });
            marker.addTo(this.group);
            marker.on("click", this.onPOIClicked);
            if (poi.popup) {
                marker.bindPopup(poi.popup);
                marker.on("mouseover", function() {
                    this.openPopup();
                });
            }
            this.markers[poi.id] = marker;
        },

        centerMapOnGroup() {
            if (this.pois.length > 0) {
                this.map.flyToBounds(this.group.getBounds().pad(0.3), {
                    maxZoom: 10,
                });
            }
        },

        centerMapOnPOI(id) {
            this.map.flyTo(this.markers[id].getLatLng(), 13);
        },

        onPOIClicked(e) {
            this.$emit("poi-clicked", e.target.options.payload);
        },
    },
};
</script>
